@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Global styles for the entire app */
body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px; /* Default font size */
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Light text color */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: #bb86fc; /* Light purple link color */
}

a:hover {
  color: #bb86fc; /* Hover effect with same color */
}

h3 {
  text-align: center;
  margin-bottom: 25px;
  font-size: 16px;
  color: #f70a0a;
  font-weight: bold;
}

button {
  background-color: #bb86fc; /* Light purple button color */
  color: #121212; /* Dark text color */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #9f6ded; /* Darker purple on hover */
}

input, textarea, select {
  font-family: 'Montserrat', sans-serif;
}
