/* src/components/Loading.css */

/* Existing styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  color: rgb(4, 1, 15);
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .loading-spinner {
      width: 80px;
      height: 80px;
      border-width: 12px;
  }
}

@media (max-width: 480px) {
  .loading-spinner {
      width: 60px;
      height: 60px;
      border-width: 8px;
  }
}
