/* src/components/Header.css */

/* Existing styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1f1f1f; /* Dark header background */
  color: #e0e0e0;
  border-bottom: 4px solid #bb86fc; /* Light purple bottom border */
  position: fixed;
  width: 98%; /* Ensuring the header spans the full width */
  top: 0;
  z-index: 1000;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.logo-text {
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  flex-wrap: wrap; /* Allows menu items to wrap to the next line if necessary */
  align-items: center; /* Align items vertically to the center */
}

.nav-links li {
  position: relative;
  display: flex;
  align-items: center; /* Align items vertically to the center */
}

.nav-links a {
  color: #e0e0e0;
  text-decoration: none;
  transition: font-size 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

.nav-links a:hover {
  color: #ffffff; /* Light purple on hover */
  font-size: 18px; /* Increase font size on hover */
  text-decoration: none; /* Remove underline on hover */
}

.logout-button {
  background-color: transparent;
  border: none;
  color: #e0e0e0;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 20px;
  transition: font-size 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

.logout-button:hover {
  color: #bb86fc; /* Light purple on hover */
  font-size: 18px; /* Increase font size on hover */
  text-decoration: none; /* Remove underline on hover */
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%; /* Position below the parent element */
  left: 0;
  background: linear-gradient(90deg, #4b6cb7, #182848); /* Bluish gradient */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: all 0.3s ease; /* Smooth transition for dropdown */
  border-radius: 4px;
  overflow: hidden; /* To ensure rounded corners on the dropdown */
}

.dropdown-content li {
  color: #e0e0e0;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: font-size 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

.dropdown-content li:hover {
  font-size: 18px; /* Increase font size on hover */
  color: #bb86fc; /* Light purple text color on hover */
  background: linear-gradient(90deg, #4b6cb7, #182848); /* Maintain bluish gradient on hover */
  text-decoration: none; /* Remove underline on hover */
}

.nav-links li:hover .dropdown-content {
  display: block;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .header {
      flex-direction: column;
      padding: 15px;
  }

  .logo-text {
      font-size: 20px;
  }

  .nav-links {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin-top: 10px;
  }

  .nav-links li {
      width: 100%;
  }

  .nav-links a {
      font-size: 16px;
      padding: 10px;
  }

  .logout-button {
      font-size: 14px;
      padding: 8px;
  }
}

@media (max-width: 480px) {
  .logo {
      height: 30px;
  }

  .logo-text {
      font-size: 18px;
  }

  .nav-links a {
      font-size: 14px;
      padding: 8px;
  }

  .dropdown-content li {
      font-size: 14px;
      padding: 8px;
  }
}
