/* src/components/Layout.css */

/* Existing styles */
.layout-container {
  display: flex;
  margin-top: 60px; /* Adjust based on the height of the header */
}

.sidebar {
  width: 250px;
  background-color: #2a2a2a; /* Dark sidebar background */
  color: white;
  padding: 10px;
  position: fixed;
  top: 65px; /* Adjust based on the height of the header */
  bottom: 0;
  overflow-y: auto;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.content {
  margin-left: 250px; /* Adjust to the width of the sidebar */
  padding: 20px;
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Light text color */
  width: calc(100% - 250px); /* Adjust to the width of the sidebar */
  min-height: 100vh;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .sidebar {
      width: 200px;
      top: 60px; /* Adjust for smaller header */
      position: absolute; /* Allow toggling for mobile menus */
      z-index: 1000;
      transform: translateX(-200px); /* Hide sidebar initially */
      transition: transform 0.3s ease-in-out;
  }

  .sidebar.active {
      transform: translateX(0); /* Show sidebar when active */
  }

  .content {
      margin-left: 0;
      width: 100%;
      padding: 15px;
  }
}

@media (max-width: 480px) {
  .sidebar {
      width: 180px;
      transform: translateX(-180px); /* Adjust based on the sidebar width */
  }

  .content {
      padding: 10px;
  }
}
